export const siteData = {
  "name": "CERTILO",
  "address": "1 RUE ALBERT COHEN,",
  "postCode": "13016",
  "city": "marseille",
  "phone": "0635315421",
  "url": "https://certilo.store",
  "urlSub": "certilo.store",
  "price": "price_1Q54ihJazRGp4FtVTFCBxeRl",
  "pk": "pk_live_51Q54dKJazRGp4FtV4jxIXWPek1Hi4PvUjOd0eN0YnnC7sZWT3etKPwUmNNobj8fj86wthTppH9cBn54Ckz3YTHIt00uPh5zwHv",
  "sk": "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"
};